// extracted by mini-css-extract-plugin
export var backAndFav = "ModuleDetail-module--back-and-fav--d6059";
export var complete = "ModuleDetail-module--complete--78e6f";
export var fav = "ModuleDetail-module--fav--69776";
export var grecaptchaBadge = "ModuleDetail-module--grecaptcha-badge--6d561";
export var hideOnDesktop = "ModuleDetail-module--hide-on-desktop--5ea49";
export var hideOnMobile = "ModuleDetail-module--hide-on-mobile--44db9";
export var iconAccountDefault = "ModuleDetail-module--icon-account-default--d0d0d";
export var iconArrow = "ModuleDetail-module--icon-arrow--9ce8e";
export var iconBrand = "ModuleDetail-module--icon-brand--840ef";
export var iconBrand1 = "ModuleDetail-module--icon-brand1--19546";
export var iconBrand2 = "ModuleDetail-module--icon-brand2--6b12d";
export var iconCalculator = "ModuleDetail-module--icon-calculator--eeca7";
export var iconCamera = "ModuleDetail-module--icon-camera--050eb";
export var iconCheckbox = "ModuleDetail-module--icon-checkbox--111d6";
export var iconChevron = "ModuleDetail-module--icon-chevron--448a0";
export var iconDelete = "ModuleDetail-module--icon-delete--81460";
export var iconDisclaimer = "ModuleDetail-module--icon-disclaimer--d42ef";
export var iconDone = "ModuleDetail-module--icon-done--9abf3";
export var iconDownload = "ModuleDetail-module--icon-download--e7361";
export var iconEdit = "ModuleDetail-module--icon-edit--5ebdc";
export var iconFacebook = "ModuleDetail-module--icon-facebook--1ea0e";
export var iconFaq = "ModuleDetail-module--icon-faq--cf3b0";
export var iconFaqUp = "ModuleDetail-module--icon-faq-up--d8548";
export var iconFilledHeart = "ModuleDetail-module--icon-filled-heart--e70e5";
export var iconFlag = "ModuleDetail-module--icon-flag--85d25";
export var iconHeart = "ModuleDetail-module--icon-heart--cd493";
export var iconInstagram = "ModuleDetail-module--icon-instagram--a86ac";
export var iconLog = "ModuleDetail-module--icon-log--78ea4";
export var iconLoginArrow = "ModuleDetail-module--icon-login-arrow--4e1a9";
export var iconLogout = "ModuleDetail-module--icon-logout--67da9";
export var iconPassword = "ModuleDetail-module--icon-password--3577f";
export var iconPinterestP = "ModuleDetail-module--icon-pinterest-p--9ff06";
export var iconRate15 = "ModuleDetail-module--icon-rate-15--1b989";
export var iconRate25 = "ModuleDetail-module--icon-rate-25--071c0";
export var iconRate35 = "ModuleDetail-module--icon-rate-35--0a7a2";
export var iconRate45 = "ModuleDetail-module--icon-rate-45--c0346";
export var iconRate55 = "ModuleDetail-module--icon-rate-55--b71a4";
export var iconRating = "ModuleDetail-module--icon-rating--ef602";
export var iconSocial = "ModuleDetail-module--icon-social--f58b0";
export var iconSocial1 = "ModuleDetail-module--icon-social1--ed90c";
export var iconSocial2 = "ModuleDetail-module--icon-social2--46a36";
export var iconStores = "ModuleDetail-module--icon-stores--a1e2b";
export var iconTrophy = "ModuleDetail-module--icon-trophy--a9822";
export var iconUser = "ModuleDetail-module--icon-user--edf94";
export var iconUserCircle = "ModuleDetail-module--icon-user-circle--adedf";
export var iconYoutube = "ModuleDetail-module--icon-youtube--05bd3";
export var inProgress = "ModuleDetail-module--in-progress--e7b6f";
export var markers = "ModuleDetail-module--markers--c9f00";
export var moduleContainer = "ModuleDetail-module--module-container--442c6";
export var moduleHeader = "ModuleDetail-module--module-header--63214";
export var notFav = "ModuleDetail-module--not-fav--581e7";
export var pageWidth = "ModuleDetail-module--page-width--867ea";
export var stepDone = "ModuleDetail-module--step-done--6485f";
export var stepStatus = "ModuleDetail-module--step-status--b14f3";
export var stepUndone = "ModuleDetail-module--step-undone--d32f0";
export var subjectCard = "ModuleDetail-module--subject-card--682e5";
export var subjectCarousel = "ModuleDetail-module--subject-carousel--82c66";
export var subjectDesktop = "ModuleDetail-module--subject-desktop--90e66";
export var subjectImage = "ModuleDetail-module--subject-image--3d1dd";
export var subjectInfos = "ModuleDetail-module--subject-infos--ec9e3";
export var subjectWrapper = "ModuleDetail-module--subject-wrapper--7309f";
export var videoContainer = "ModuleDetail-module--video-container--0722b";
export var view = "ModuleDetail-module--view--3c412";
export var views = "ModuleDetail-module--views--a438d";