import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import GoBackButton from './GoBackButton'
import * as st from '~/assets/styl/ModuleDetail.module.styl'

import { moduloMock } from '~/data'
import { Link } from 'gatsby'
import Slider from '@fil1pe/react-slider'
import { useContext } from '~/hooks'

import api from '~/siteApi'
import { useMediaQuery } from 'react-responsive'

type Props = {
  pageContext: {
    id: number
    image: string
    material: string
    name: string
    video: string
    video_code: string
    slug: string
    description: string
    lessons: Array<{
      id: number
      name: string
      slug: string
      steps: Array<{
        id: number
        imageCover: string
        cover: string
        rating: number
        name: string
        description: string
        finished: boolean
        rated: boolean
        video: string
      }>
    }>
  }
}

export default function ModuleDetail({
  pageContext: { id, image, description, lessons, name, material, video_code },
}: Props) {
  const { profile, token, setProfile } = useContext()
  const isLogged = Boolean(profile)
  const isDesktop = useMediaQuery({ query: '(min-width: 1081px)' })
  const embedVideo = `https://www.youtube.com/embed/${video_code}`

  const favs = (profile?.studies_favorites || []).map((fav) => fav.study)
  const complete = (profile?.account_steps || []).map((comp) => comp.step)

  function favorite(e, id) {
    e.preventDefault()
    const { studies_favorites } = profile
    api('patch', `studies/${id}/favorite`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        studies_favorites.push({ study: id })
        setProfile(profile)
      })
      .catch(([errors]) => {
        console.error(errors)
      })
  }

  function disfavor(e, id) {
    e.preventDefault()
    const { studies_favorites } = profile
    api('patch', `studies/${id}/disfavor`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        studies_favorites.splice(
          studies_favorites.findIndex(({ study }) => study === id),
          1
        )
        setProfile(profile)
      })
      .catch(([errors]) => {
        console.error(errors)
      })
  }

  return (
    <div className={cn(st.moduleContainer, 'page-width')}>
      <div className={st.backAndFav}>
        <GoBackButton />
        {isLogged && (
          <>
            {favs.includes(id) ? (
              <div onClick={(e) => disfavor(e, id)} className={st.fav}>
                <span className="icon-filled-heart"></span>
                Módulo favoritado
              </div>
            ) : (
              <div onClick={(e) => favorite(e, id)} className={st.notFav}>
                <span className="icon-heart"></span>
                Favoritar módulo
              </div>
            )}
          </>
        )}
      </div>
      {video_code && (
        <div className={st.videoContainer}>
          <iframe
            src={embedVideo}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </div>
      )}
      <section className={st.moduleHeader}>
        <h1>{name}</h1>
        {material && (
          <>
            <p>Baixe aqui o material didático deste módulo</p>
            <Link target="_blank" to={material}>
              <span className="icon-download"></span>
              Baixar material
            </Link>
          </>
        )}
      </section>
      {lessons &&
        lessons.map((subject, id) => (
          <section className={st.subjectWrapper} key={id}>
            <h2>{subject.name}</h2>
            {!isDesktop ? (
              <Slider
                slidesToShow={2}
                slidesToScroll={1}
                finite={true}
                className={st.subjectCarousel}
                renderArrow={(props) => <button {...props}></button>}
              >
                {subject.steps.map((step) => (
                  <Link key={step.id} to={'/passo/' + step.id}>
                    <div className={st.subjectCard}>
                      <div
                        className={st.subjectImage}
                        style={{
                          backgroundImage: `url(${step.cover})`,
                        }}
                      >
                        {isLogged && (
                          <div className={st.markers}>
                            {complete.includes(step.id) ? (
                              <span
                                className={cn(st.stepDone, 'icon-done')}
                              ></span>
                            ) : (
                              <span
                                className={cn(st.stepUndone, 'icon-disclaimer')}
                              ></span>
                            )}
                          </div>
                        )}
                      </div>
                      <section className={st.subjectInfos}>
                        <h3>
                          <b>{step.name}</b>
                        </h3>
                        {step.description && <p>{step.description}</p>}
                        {isLogged && (
                          <div className={st.stepStatus}>
                            {complete.includes(step.id) ? (
                              <div className={st.complete}>
                                <span className="icon-done"></span>
                                Concluído
                              </div>
                            ) : (
                              <div className={st.inProgress}>Em andamento</div>
                            )}
                          </div>
                        )}
                      </section>
                    </div>
                  </Link>
                ))}
              </Slider>
            ) : (
              <div className={st.subjectDesktop}>
                {subject.steps.map((step) => (
                  <Link key={step.id} to={'/passo/' + step.id}>
                    <div className={st.subjectCard}>
                      <div
                        className={st.subjectImage}
                        style={{
                          backgroundImage: `url(${step.cover})`,
                        }}
                      >
                        {isLogged && (
                          <div className={st.markers}>
                            {complete.includes(step.id) ? (
                              <span
                                className={cn(st.stepDone, 'icon-done')}
                              ></span>
                            ) : (
                              <span
                                className={cn(st.stepUndone, 'icon-disclaimer')}
                              ></span>
                            )}
                          </div>
                        )}
                      </div>
                      <section className={st.subjectInfos}>
                        <h3>
                          <b>{step.name}</b>
                        </h3>
                        {step.description && <p>{step.description}aa</p>}
                        {isLogged && (
                          <div className={st.stepStatus}>
                            {complete.includes(step.id) ? (
                              <div className={st.complete}>
                                <span className="icon-done"></span>
                                Concluído
                              </div>
                            ) : (
                              <div className={st.inProgress}>Em andamento</div>
                            )}
                          </div>
                        )}
                      </section>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </section>
        ))}
    </div>
  )
}
