import React from 'react'
import { Head } from '~/components'
import ModuleDetail from '~/components/ModuleDetail'
import Newsletter from '~/components/Newsletter'
import { useApi } from '~/siteApi'

const Modulo = ({ pageContext, '*': slug }) => {
  const context = useApi(pageContext, 'studies/' + (pageContext.slug || slug))
  return (
    <>
      <Head />
      <ModuleDetail pageContext={context} />
      <Newsletter />
    </>
  )
}

export default Modulo
